<template>
  <div class="user">
    
    <!-- 顶部卡片 -->
    <el-card>
      <el-form ref="form" :model="queryInfo" label-width="90px">
        <el-form-item label="用户类型：">
          <el-radio-group v-model="queryInfo.userType" @change="search">
            <el-radio-button :label="item.lable" v-for="(item, index) in userType" :key="index">{{
              item.name
            }}</el-radio-button>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="用户职位：">
          <el-radio-group v-model="queryInfo.position" @change="search">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button
              :label="item.name"
              v-for="(item, index) in userPosition"
              :key="index"
              >{{ item.name }}</el-radio-button
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="创建日期：">
          <el-radio-group v-model="queryInfo.createTime" @change="search">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="1">今日</el-radio-button>
            <el-radio-button label="2">一周内</el-radio-button>
            <el-radio-button label="3">一年内</el-radio-button>
          </el-radio-group>
          <el-date-picker
            @change="timechange"
            v-model="timeList"
            type="daterange"
            unlink-panels
            start-placeholder="请选择日期"
            end-placeholder="请选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="所在地区：" prop="">
          <el-select v-model="queryInfo.province" placeholder="请选择省份" @change="citychange">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div style="display: inline-block; width: 10px"></div>
          <el-select v-model="queryInfo.city" placeholder="请选择市" @change="districtchange">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in operatingLists"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
          <div style="display: inline-block; width: 10px"></div>
          <el-select v-model="queryInfo.district" placeholder="请选择区" @change="search">
            <el-option :value="''" label="全部"></el-option>
            <el-option
              v-for="item in districtList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-card>
    <!-- 底部卡片 -->
    <el-card style="margin-top: 20px">
      <div class="search" style="width: 100%; display: flex; justify-content: space-between">
        <el-button  plain @click="exportExcel"   v-if="$hasPerms('user:export')">批量导出</el-button>

        <div class="search_right">
          <el-input
            placeholder="请输入企业全称、用户名称、手机号"
            v-model="queryInfo.keys"
            class="input-with-select"
            clearable
          >
            <el-button slot="append" @click="search">搜索</el-button>
          </el-input>
        </div>
      </div>
      <!-- 表单区域 -->
      <el-table
        v-loading="loading"
        class="table_data"
        :header-cell-style="{ background: '#F8F9FB', color: '#595959', 'text-align': 'center' }"
        :data="tableData"
        ref="multipleTable"
        style="width: 100%"
        tooltip-effect="dark"
        :row-key="getRowKey"
        @sort-change="changeSort"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="50" align="center" :reserve-selection="true" />
        <el-table-column prop="id" label="编号" align="center" width="120"> </el-table-column>
        <el-table-column label="微信头像" align="center" width="100">
          <template>
            <img
              src="https://ginseng-wisdom-1300257084.cos.ap-nanjing.myqcloud.com/system_img_dev/202302061802.png"
              class="img"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column prop="realName" label="姓名" align="center" width="80">
          <template v-slot="{ row }">
            <div style="display: flex; align-items: flex-end">
              <div>
                <span v-if="row.realName">{{ row.realName }}</span>
                <span v-else>{{ row.nickName }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="90" align="center"> </el-table-column>
        <el-table-column prop="address" label="所在城市" align="center"> </el-table-column>
        <el-table-column prop="position" label="职位" width="80" align="center"> </el-table-column>
        <el-table-column prop="companyFullName" label="企业全称" align="center"> </el-table-column>

        <el-table-column label="用户创建日期" prop="createTime" sortable align="center" width="130">
          <template v-slot="{ row }">
            <div>
              <span>{{ row.createTime }}</span>
            </div>
            <div v-if="today == row.createTime" class="type_xuan">
              <span>今日新增用户</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="applyStatus" label="用户状态 " align="center">
          <template v-slot="{ row }">
            <div style="display: flex; justify-content: center">
              <div class="formal" v-if="row.userLevel == '0'">普通用户</div>
              <div class="tourist" v-if="row.userLevel == '1'">个人会员</div>
              <div class="tourist" v-if="row.userLevel == '2'">企业会员</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="businessCard " label="名片" align="center">
          <template v-slot="{ row }">
            <div class="img_list" v-if="row.businessCard">
              <el-popover
                trigger="hover"
                width="350"
                v-for="(item, index) in row.businessCard.split(',')"
                :key="index"
                class="popover"
                placement="left"
              >
                <div slot="reference">
                  <img v-if="item" class="new_img" width="350" height="200" :src="item" alt="" />
                </div>
                <img v-if="item" class="new_img" width="350" height="200" :src="item" alt="" />
              </el-popover>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="applyStatus" label="企业账号有效期 " align="center">
          <template v-slot="{ row }">
            <div v-if="row.beginTime">
              <div>始{{ row.beginTime }}</div>
              <div>终{{ row.deadline }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="loginCount" label="登录次数" sortable align="center">
        </el-table-column>
        <el-table-column prop="address" label="IP/城市名" align="center">
          <template v-slot="{ row }">
            <span>{{ row.ip }}/{{ row.address }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updateTime" width="130" label="最近登录时间" sortable align="center">
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <div class="new_page">
        <el-pagination
          :current-page="queryInfo.pageNum"
          :page-sizes="[10, 30, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </el-card>
  </div>
</template>

<script>
import { listPage, exportExcel } from '@/api/user'
import { getCityAll } from '@/api/demand'
const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  city: '', //所在地区
  userSchema: '', //企业合作伙伴
  position: '', //职位;1= CEO, 2 = CIO, 3=COO,4=IT负责人,5=项目负责人,6=其他
  keys: null, //企业全称/用户名称/手机号
  userType: '', //用户类型 0->普通用户;1->个人会员;2->企业会员
  createTime: '', //创建日期 1->今日;2->一周内;3->一年内
  startTime: null, //开始时间
  province: '', //所在省
  district: '', //区/县
  directLeague:'',//直营或加盟(1->直营;2->加盟) 
  schemaName:'',//库名前缀
  cityPartner:'',//城市合伙人标识；1代表是城市合伙人
  cityPartnerId:'',//城市合伙人类型的合作伙伴关联的伙伴表主键，用,隔开
  orders: '1', //排序 默认传1 2->创建日期升序;3->创建日期降序;4->登陆次数升序;5->登陆次数降序;6->最近登陆时间升序;7->最近登陆时间降序
  endTime: null //结束时间
})
export default {
  // components: { CitySelect },
  name: 'User',
  data() {
    return {
      // 遮罩层
      loading: true,
      tableData: [], //表格数据
      total: 0, //总数
      ids: [],
      queryInfo: { ...defaultQueryInfo },
      today: '', //今日日期
      timeList: [],
      userType: [
        {
          name: '全部',
          lable: ''
        },
        {
          name: '普通用户',
          lable: 0
        },
        {
          name: '个人用户',
          lable: 1
        },
        {
          name: '企业用户',
          lable: 2
        }
      ], //用户类型
      parteninfo:JSON.parse(sessionStorage.getItem('typeMode')),
      operatingList: [], //省
      operatingLists: [], //市
      districtList: [], //区
      userPosition: [
        {
          name: 'CEO',
          lable: '1'
        },
        {
          name: 'CIO',
          lable: '1'
        },
        {
          name: 'IT负责人',
          lable: '2'
        },
        {
          name: '项目负责人',
          lable: '3'
        },
        {
          name: '其他',
          lable: '4'
        }
      ] //用户职位：
    }
  },
  created() {
    var date = new Date() // Sat Jul 06 2019 19:59:27 GMT+0800 (中国标准时间)
    var year = date.getFullYear() // 2019
    var month = date.getMonth() + 1 // 7
    var day = date.getDay() // 6
    month = month > 9 ? month : '0' + month
    day = day < 10 ? '0' + day : day
    this.today = year + '-' + month + '-' + day // 2019-07-06
    this.search()
    this.incity()
  },
  methods: {
    //城市
    async incity() {
      const res = await getCityAll()
      if (res.data.resultCode == 200) {
        this.operatingList = res.data.data
      }
    },
    //选择城市
    citychange(item) {
      console.log(item)
      this.operatingList.forEach((o) => {
        if (this.queryInfo.province == o.name) {
          this.queryInfo.city = ''
          if (item == '全部') {
            this.operatingLists = this.operatingList.map((el) => el.cityResults).flat(Infinity)
          } else {
            let isnum = item.indexOf('市')
            if (isnum == -1) {
              this.operatingLists = this.operatingList.find((el) => el.name == item).cityResults
            } else {
              this.queryInfo.city = item
              this.districtList = this.operatingList.find((el) => el.name == item).cityResults
            }
          }
        }
      })
      this.search()
    },
    //选择区
    districtchange(item) {
      this.operatingLists.forEach((o) => {
        if (this.queryInfo.city == o.name) {
          this.queryInfo.district = ''
          if (item == '全部') {
            this.districtList = this.operatingLists.map((el) => el.cityResults).flat(Infinity)
          } else {
            this.districtList = this.operatingLists.find((el) => el.name == item).cityResults
          }
        }
      })
      this.search()
    },
    //选择时间
    timechange(val) {
      if (val) {
        this.queryInfo.startTime = val[0] + ' 24:00:00'
        this.queryInfo.endTime = val[1] + ' 24:00:00'
        this.search()
      } else {
        this.queryInfo.startTime = null
        this.queryInfo.endTime = null
        this.search()
      }
    },

    getRowKey(row) {
      return row.id
    },
    //多选
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    //获取表格页面数据
    async search() {
      this.loading = true
      console.log(this.parteninfo);
      this.queryInfo.userSchema=this.parteninfo.id
      this.queryInfo.schemaName=this.parteninfo.schemaName
      this.queryInfo.directLeague=this.parteninfo.directLeague
      
      this.queryInfo.cityPartner=this.parteninfo.cityPartner
      this.queryInfo.cityPartnerId=this.parteninfo.cityPartnerId
      const { data: res } = await listPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.tableData = res.data.list
        this.total = res.data.total
        this.loading = false
      } else {
        this.$message.error(res.message)
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    //返回省
    searchProvince(val) {
      this.queryInfo.province = val
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val

      this.search()
    },
    //批量导出
    async exportExcel() {
      this.loading = true
      let query = {
        city: this.queryInfo.city, //所在地区
        userSchema: this.parteninfo.id, //企业合作伙伴
        position: this.queryInfo.position, //职位;1= CEO, 2 = CIO, 3=COO,4=IT负责人,5=项目负责人,6=其他
        keys: this.queryInfo.keys, //企业全称/用户名称/手机号
        userType: this.queryInfo.userType, //用户类型 0->普通用户;1->个人会员;2->企业会员
        createTime: this.queryInfo.createTime, //创建日期 1->今日;2->一周内;3->一年内
        startTime: this.queryInfo.startTime, //开始时间
        province: this.queryInfo.province, //所在省
        district: this.queryInfo.district, //区/县
        orders: this.queryInfo.orders, //排序 默认传1 2->创建日期升序;3->创建日期降序;4->登陆次数升序;5->登陆次数降序;6->最近登陆时间升序;7->最近登陆时间降序
        endTime: this.queryInfo.endTime, //结束时间
        cityPartner:this.parteninfo.cityPartner,
      schemaName:this.parteninfo.schemaName,
      directLeague:this.parteninfo.directLeague,
      cityPartnerId:this.parteninfo.cityPartnerId
      }
      if (this.ids.length) {
        query['ids'] = this.ids
      }
     
      const res = await exportExcel(query)
      if (res.data) {
        this.loading = false
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `用户数据${nowtime}.xlsx` //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
        delete this.queryInfo.ids
        this.search()
        this.$refs.multipleTable.clearSelection()
        this.isIndeterminate = false
        this.checkAll = false
      } else {
        this.$message.error('下载失败!')
      }
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      // 2->创建日期升序;3->创建日期降序;4->登陆次数升序;5->登陆次数降序;6->最近登陆时间升序;7->最近登陆时间降序
      console.log(val)
      if (val.prop == 'loginCount') {
        if (val.order == 'ascending') {
          //updateAt列升序时
          this.queryInfo.orders = '4'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orders = '5'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orders = '1'
          this.search()
        }
      } else if (val.prop == 'updateTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orders = '6'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orders = '7'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orders = '1'
          this.search()
        }
      } else if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orders = '2'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orders = '3'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orders = '1'
          this.search()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.user {
  width: 100%;
  overflow: hidden;

  /deep/ .el-radio-button__inner {
    border: none;
    margin-right: 12px;
    border-radius: 4px;
  }

  .table_data {
    .img {
      width: 42px;
      height: 42px;
      display: block;
      margin: 0 auto;
      border-radius: 4px;
    }

    .type_can {
      display: flex;
      flex-direction: row;
      align-items: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #ff7d18;
        border-radius: 50%;
      }

      span {
        color: #ff7d18;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .type_xuan {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      em {
        width: 8px;
        height: 8px;
        display: inline-block;
        margin-right: 6px;
        background: #001aff;
        border-radius: 50%;
      }

      span {
        color: #001aff;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .formal {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(78, 147, 251, 0.1);
      color: #4e93fb;
      border-radius: 2px;
    }

    .tourist {
      width: 72px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 125, 24, 0.1);
      color: #ff7d18;
      border-radius: 2px;
    }

    .img_list {
      .new_img {
        width: 70px;
        height: 40px;
        border-radius: 4px;
        margin-right: 10px;
      }
    }
  }

  .search {
    width: 100%;
    margin: 20px 0;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
  }

  .search_right {
    width: 560px;

    /deep/ .el-button {
      border: 1px solid #448aff;
      color: #fff;
      border-radius: 0;
      background-color: #448aff;
    }
  }

  .new_page {
    width: 100%;
    text-align: center;
    margin: 20px 0;
  }
}
</style>
